<section id="banner-inicial">
  <div [ngStyle]="{'background-image': 'url(assets/web-estatica/inicio/inicio-contacto.webp)'}"
       style="min-height: 50vh !important; background-size: cover; background-position: center;"
       class="w-100 d-flex align-items-center">
    <div class="row"></div>
    <div class="col-md-4 col-0"></div>
    <div class="col-md-5 col-12">
      <h3 style="font-size: 1.875em !important;">
        Estamos encantados de poder atenderle
      </h3>
      <h1 style="font-size: 3.75em !important; line-height: 70px;" class="font-weight-bold">
        ¡Contacta con nosotros!
      </h1>
      <button class="btn btn-primary text-light font-weight-bold mt-2 p-2 rounded-button">
        Contactar
      </button>
    </div>

  </div>
</section>

<section id="contacto">
  <div class="mt-5">
    <div class="container-fluid pb-3">
      <div class="row">
        <div class="col-md-5" [ngStyle]="{'background-image': 'url(assets/web-estatica/inicio/contacta-con-nosotros.webp)'}"
             style="background-size: cover; background-position: center;">

        </div>

        <div class="col-md-7 pt-3 pb-3">
          <h2 style="font-size: 2.5em !important;" class="font-weight-bold">¡Estamos para ayudar!</h2>
          <p>Rellene el formulario con cualquier consulta y le responderemos lo antes posible</p>
          <!-- Formulario de contacto -->
          <form [formGroup]="contactForm" (ngSubmit)="sendMail()">
            <mat-card style="border: 1px solid lightgray;">
              <div class="row">
                <div class="col-md-12 p-2 text-center" style="background-color: rgba(0,155,0,0.42)"
                     *ngIf="successMessage">
                  <b>{{successMessage}}</b>
                </div>

                <div class="col-md-12 p-2 text-center text-white" style="background-color: rgba(255,0,41,0.83)"
                     *ngIf="errorMessage">
                  <b>{{errorMessage}}</b>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <mat-form-field class="w-100">
                    <mat-label>Nombre</mat-label>
                    <input matInput type="name" formControlName="name" required/>
                    <div *ngIf="contactForm.controls.name.touched && contactForm.controls.name.errors?.required"
                         class="error">Campo obligatorio.
                    </div>
                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field class="w-100">
                    <mat-label>Número de teléfono</mat-label>
                    <input matInput formControlName="telephone" required/>
                    <div *ngIf="contactForm.controls.telephone.touched && contactForm.controls.telephone.errors?.required"
                         class="error">Campo obligatorio.
                    </div>
                    <div *ngIf="contactForm.controls.telephone.touched && contactForm.controls.telephone.errors?.pattern"
                         class="error">Formato incorrecto.
                    </div>
                  </mat-form-field>
                </div>

                <div class="col-12">
                  <mat-form-field class="w-100">
                    <mat-label>Correo electrónico</mat-label>
                    <input matInput name = "email" placeholder="Ej: correo@hotmail.com" formControlName="email" required/>
                    <div *ngIf="contactForm.controls.email.touched && contactForm.controls.email.errors?.email"
                         class="error">Formato incorrecto.
                    </div>
                  </mat-form-field>
                </div>

                <div class="col-12">
                  <mat-form-field  class="w-100">
                    <mat-label>¿Por qué nos contactas?</mat-label>
                    <textarea matInput name = "message" formControlName="message" required></textarea>
                    <div *ngIf="contactForm.controls.message.touched && contactForm.controls.message.errors?.required"
                         class="error">Campo obligatorio.
                    </div>
                  </mat-form-field>
                </div>
              </div>

              <mat-checkbox color="primary" name="isActive" id="mailCheck" formControlName="accept" required>
                Acepto las <a routerLink="/politica-de-privacidad">condiciones y términos de uso</a>
              </mat-checkbox>
              <mat-hint class="form-text error"
                        *ngIf="contactForm.controls.accept.touched && contactForm.controls.accept.errors?.required">Debes aceptar esta opción para continuar.</mat-hint><br>
              <div class="d-flex justify-content-center w-100">
                <button class="btn btn-primary" type="submit" mat-raised-button
                        [disabled]="contactForm.invalid">
                  Enviar
                </button>
              </div>
            </mat-card>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>

<section id="ubicaciones" style="min-height: 700px !important;" >
  <div *ngIf="companyConf != undefined">
    <div *ngIf="companyConf.rutasRecogida != undefined" class="container-fluid mt-5 mb-5">
      <h2 style="font-size: 2.5em !important;" class="font-weight-bold">Encuéntranos en las siguientes ubicaciones</h2>
      <div class="row mt-3">
        <div class="col-lg-3 col-md-4 col-6 border text-center" *ngFor="let ruta of companyConf.rutasRecogida; else elseUbicaciones">
          <div class="mt-5 mb-5 ml-2 mr-2">
            <i class="fa-solid fa-location-dot fa-3x text-primary pointer"
               (click)="openExternalLinkRuta('https://www.google.com/maps/search/', ruta.nombre)"></i>
            <h3 class="mb-0 font-weight-bold mt-1">{{formatearNombreRuta(ruta.nombre)}}</h3>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-template #elseUbicaciones>
    <div class="container-fluid mt-5 mb-5" *ngIf="contactoJSON">
      <h2 style="font-size: 2.5em !important;" class="font-weight-bold">Encuéntranos en las siguientes ubicaciones</h2>
      <div class="row mt-3">
        <div class="col-lg-3 col-md-4 col-6 border text-center" *ngFor="let ubicacion of contactoJSON.ubicaciones">
          <div class="mt-5 mb-5 ml-2 mr-2">
            <i class="fa-solid fa-location-dot fa-3x text-primary"></i>
            <h3 class="mb-0 font-weight-bold mt-1">{{ubicacion.ubicacion}}</h3>
            <p>
              {{ubicacion.direccion}}<br/>
              {{ubicacion.codigoPostal}} - {{ubicacion.ciudad}}<br/>
              <a href="tel:{{ubicacion.telefono}}">{{ubicacion.telefono}}</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</section>

