import { Injectable } from '@angular/core';
import {ArticleCookie} from "../interfaces/article";
import {Router} from '@angular/router';
import {SuperGroup} from '../interfaces/group';
import {CarArticle} from "../interfaces/car-article";
import {ShoppingCarService} from "./shopping-car.service";

const articlesStorage = 'ARTICLES';
const defaultLink = 'LINK';
const companyType = 'COMPANY_TYPE';
const showShoppingCar = 'SHOW_SHOPPING_CAR';
const userStorage = 'TOKEN';
const superGroup = 'SUPER_GROUP';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor(private router: Router) { }

  setCompanyType(type: string) {
    localStorage.setItem(companyType, type);
  }

  setShowShoppingCar(show: string) {
    localStorage.setItem(showShoppingCar, show);
  }

  getCompanyType() {
    if (localStorage.getItem(companyType) === '' || localStorage.getItem(companyType) == null) {
      return 'P';
    } else {
      return  localStorage.getItem(companyType);
    }
  }

  getShowShoppingCar() {
    if (localStorage.getItem(showShoppingCar) === '' || localStorage.getItem(showShoppingCar) == null) {
      return true;
    } else {
      return localStorage.getItem(showShoppingCar) == 'true';
    }
  }

  addLocalStorageArticle(arg: ArticleCookie): boolean {
    if (localStorage.getItem(userStorage) !== '' && localStorage.getItem(userStorage) != null ) {
      return false;
    } else if (arg.quantity != undefined && arg.quantity != 0) {
      let articles = this.getLocalStorageArticles();
      if (articles != null && Array.isArray(articles)) {
        const pos = articles.findIndex(e => e.id === arg.id && e.cutType === arg.cutType);
        if (pos != -1) {
          articles[pos].quantity += arg.quantity;
          articles[pos].lumps += arg.lumps;
          articles[pos].totalPrice += articles[pos].totalPrice;
        } else {
          arg.line = articles.length + 1;
          articles.push(arg);
        }
        localStorage.setItem(articlesStorage, JSON.stringify(articles));
      } else if (articles == null) {
        arg.line = 1;
        localStorage.setItem(articlesStorage, JSON.stringify(arg));
      } else {
        if (articles.id === arg.id && articles.cutType === arg.cutType) {
          arg.observations = articles.observations;
          arg.quantity = arg.quantity + articles.quantity;
          arg.lumps = arg.lumps + articles.lumps;
          arg.totalPrice = arg.totalPrice + articles.totalPrice;
          localStorage.setItem(articlesStorage, JSON.stringify(arg));
        } else {
          arg.line = 2;
        }
        const arrayArticles = [articles, arg];
        localStorage.setItem(articlesStorage, JSON.stringify(arrayArticles));
      }
    }
    return true;
  }

  getLocalStorageArticles() {
    if (localStorage.getItem(articlesStorage) === '' || localStorage.getItem(articlesStorage) == null) {
      return null;
    } else {
      return  JSON.parse(localStorage.getItem(articlesStorage));
    }
  }

  getLocalStorageArticleQuantity(id): number {
    if (localStorage.getItem(articlesStorage) === '' || localStorage.getItem(articlesStorage) == null) {
      return 0;
    } else {
      const arts = JSON.parse(localStorage.getItem(articlesStorage));
      let articleStorage = null;
      if (Array.isArray(arts)) {
        articleStorage = arts.find(e => e.id == id);
      } else if (arts.id == id){
        articleStorage = arts;
      }

      if (articleStorage == null){
        return 0;
      }
      return articleStorage.quantity;
    }
  }

  setObservations(article, line, observation): boolean {
    if (localStorage.getItem(userStorage) !== '' && localStorage.getItem(userStorage) != null) {
      return false;
    } else {
      let articles = this.getLocalStorageArticles();
      if (articles != null && Array.isArray(articles)) {
        const pos = articles.findIndex(e => e.id === article && e.line === line);
        if (pos != -1) {
          articles[pos].observations = observation;
        }
        localStorage.setItem(articlesStorage, JSON.stringify(articles));
      } else if (articles != null) {
        if (articles.id === article) {
          articles.observations = observation;
          localStorage.setItem(articlesStorage, JSON.stringify(articles));
        }
      }
      return true;
    }
  }

  setLumps(article, line, lumps): boolean {
    if (localStorage.getItem(userStorage) !== '' && localStorage.getItem(userStorage) != null) {
      return false;
    } else {
      let articles = this.getLocalStorageArticles();
      if (articles != null && Array.isArray(articles)) {
        const pos = articles.findIndex(e => e.id === article && e.line === line);
        if (pos != -1) {
          articles[pos].lumps = lumps;
        }
        localStorage.setItem(articlesStorage, JSON.stringify(articles));
      } else if (articles != null) {
        if (articles.id === article) {
          articles.lumps = lumps;
          localStorage.setItem(articlesStorage, JSON.stringify(articles));
        }
      }
      return true;
    }
  }

  setCutType(article, line, cutType): boolean {
    if (localStorage.getItem(userStorage) !== '' && localStorage.getItem(userStorage) != null) {
      return false;
    } else {
      let articles = this.getLocalStorageArticles();
      if (articles != null && Array.isArray(articles)) {
        const pos = articles.findIndex(e => e.id === article && e.line === line);
        if (pos != -1) {
          articles[pos].cutType = cutType;
        }
        localStorage.setItem(articlesStorage, JSON.stringify(articles));
      } else if (articles != null) {
        if (articles.id === article && articles.cutType === cutType) {
          articles.cutType = cutType;
          localStorage.setItem(articlesStorage, JSON.stringify(articles));
        }
      }
      return true;
    }
  }

  removeAllArticles() {
    localStorage.removeItem(articlesStorage);
  }

  removeArticle(articleId: string, line, cutType: string) {
    if (localStorage.getItem(articlesStorage) === '' || localStorage.getItem(articlesStorage) == null) {
      return null;
    } else {
      let articles = this.getLocalStorageArticles();
      if (articles != null && Array.isArray(articles)) {
        articles = articles.filter( e => !(e.id === articleId && e.line === line && e.cutType === cutType));
        localStorage.setItem(articlesStorage, JSON.stringify(articles));
      } else if (articles != null && articles.id === articleId) {
        this.removeAllArticles();
      }
    }
  }

  removeFruitArticle(articleId: string) {
    if (localStorage.getItem(articlesStorage) === '' || localStorage.getItem(articlesStorage) == null) {
      return null;
    } else {
      let articles = this.getLocalStorageArticles();
      if (articles != null && Array.isArray(articles)) {
        articles = articles.filter( e => !(e.id === articleId));
        localStorage.setItem(articlesStorage, JSON.stringify(articles));
      } else if (articles != null && articles.id === articleId) {
        this.removeAllArticles();
      }
    }
  }

  getDefaultLink() {
    if (localStorage.getItem(defaultLink) === '' || localStorage.getItem(defaultLink) == null) {
      return '/compra/of';
    } else {
      return localStorage.getItem(defaultLink);
    }
  }

  navigateToLogin() {
    const link = this.getDefaultLink();
    this.router.navigateByUrl(link).then();
  }

  setDefaultLink(link: string) {
    localStorage.setItem(defaultLink, link);
  }

  getSelectedOrderSuperGroup() {
    if (localStorage.getItem(superGroup) == null) {return null;}
    return JSON.parse(localStorage.getItem(superGroup));
  }

  setSelectedOrderSuperGroup(sg: SuperGroup) {
    localStorage.setItem(superGroup, JSON.stringify(sg));
  }
}
