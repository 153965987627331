import {Component, OnInit} from '@angular/core';
import {CompanyConf, CompanyInfo} from '../../../interfaces/company-conf';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.css']
})
export class PrivacyPolicyComponent implements OnInit {

  datosLegalesEmpresa: CompanyInfo;
  companyConf: CompanyConf;
  constructor() { }

  ngOnInit(): void {}

}
