import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {endpoints} from '../../../environments/endpoints';
import {ArticleOrder} from '../interfaces/article-order';
import {AppConfig} from '../../app.config';

@Injectable({
  providedIn: 'root'
})
export class ShoppingCarService {
  environmentUrl;
  constructor(private httpClient: HttpClient, private config: AppConfig) {
    this.environmentUrl = this.config.getConfig('host');
  }

  addArticle(article: string, format: string, quantity: number, lumps: number, cutType: string, newLine) {
    if (cutType === '') {
      cutType = null;
    }

    return this.httpClient.post(this.environmentUrl + endpoints.addPendingArticle,
{
        article: article,
        format: format,
        quantity: quantity,
        lumps: lumps,
        cutType: cutType,
        newLine: newLine
      }
    );
  }

  addArticles(articles) {
    return this.httpClient.post(this.environmentUrl + endpoints.addPendingArticles, {articles});
  }

  getPendingArticles(articlesStr: string, superGroup: string) {
    return this.httpClient.post(this.environmentUrl + endpoints.getPendingArticles, {articlesStr, superGroup});
  }

  pagoPaycomet() {
    return this.httpClient.get(this.environmentUrl + endpoints.pagoPaycomet);
  }

  deleteArticle(article: string, line, cutType) {
    if (cutType === '') {
      cutType = null;
    }
    return this.httpClient.post(this.environmentUrl + endpoints.deletePendingArticle,
      {
        article: article,
        line: line,
        cutType: cutType
      }
      );
  }

  deletePendingArticles(articles) {
    return this.httpClient.post(this.environmentUrl + endpoints.DeletePendingArticles, {Articles: articles});
  }

  setListArticlesQuantity(articles) {
    return this.httpClient.post(this.environmentUrl + endpoints.SetListArticlesQuantity, {Articles: articles});
  }

  setObservations(article: string, line: number, description: string) {
    if (description != null) {
      description = description.replace('\'', '`');
    }

    return this.httpClient.post(this.environmentUrl + endpoints.setObservations, {
      id: article,
      line,
      value: description
    });

  }

  setQuantity(article: string, line: number, quantity: number, lumps: number, format: string) {
    return this.httpClient.post(this.environmentUrl + endpoints.setQuantity, {
      id: article,
      line,
      quantity,
      lumps,
      format
    });

  }

  setLumps(article: string, line: number, lumps: number) {
    return this.httpClient.post(this.environmentUrl + endpoints.setLumps, {
      id: article,
      line,
      value: lumps
    });
  }

  setCutType(article: string, line: number, cut: number) {
    return this.httpClient.post(this.environmentUrl + endpoints.setCutType, {
      id: article,
      line,
      value: cut
    });
  }

  makeOrder(name: string, direction: string, province: string, city: string, country: string,
            observations: string, commercialObservations: string, referenceOrder: string,
            articles: Array<ArticleOrder>, phone: string,
            deliveryDate: string, storePickup: boolean, storePickupSchedule: string,
            email: string, deliveryPrice: number,
            usuMod: string, selfSale: boolean) {
    let body;
    if (observations != null) {
      observations = observations.replace('\'', '`');
    }

    if (direction != null) {
      direction = direction.replace('\'', '`');
    }

    if (name == null && direction == null) {
      body = {
        observations,
        commercialObservations,
        referenceOrder,
        articles,
        deliveryDate,
        storePickup,
        storePickupSchedule,
        email,
        deliveryPrice,
        usuMod,
        selfSale
      };
    } else if (name == null) {
      body = {
        direction,
        province,
        city,
        country,
        observations,
        commercialObservations,
        referenceOrder,
        articles,
        phone,
        deliveryDate,
        storePickup,
        storePickupSchedule,
        email,
        deliveryPrice,
        usuMod,
        selfSale
      };
    } else {
      body = {
        name: name.replace('\'', '`'),
        direction,
        province,
        city,
        country,
        observations,
        commercialObservations,
        referenceOrder,
        articles,
        phone,
        deliveryDate,
        storePickup,
        storePickupSchedule,
        email,
        deliveryPrice,
        usuMod,
        selfSale
      };
    }

    return this.httpClient.post(this.environmentUrl + endpoints.makeOrder, body);
  }

  pendingOrder(name: string, direction: string, province: string, city: string, country: string, observations: string,
               articles: Array<ArticleOrder>, phone: string, deliveryDate: string, storePickup: boolean, storePickupSchedule: string,
               email: string, deliveryPrice: number, ruta: number | undefined, descuento: number) {
    let body;
    if (observations != null) {
      observations = observations.replace('\'', '`');
    }

    if (direction != null) {
      direction = direction.replace('\'', '`');
    }

    if (name == null && direction == null) {
      body = {
        observations: observations,
        articles: articles,
        deliveryDate: deliveryDate,
        storePickup: storePickup,
        storePickupSchedule,
        email: email,
        deliveryPrice: deliveryPrice,
        ruta,
        descuento
      };
    } else if (name == null) {
      body = {
        direction: direction,
        province: province,
        city: city,
        country: country,
        observations: observations,
        articles: articles,
        phone: phone,
        deliveryDate: deliveryDate,
        storePickup: storePickup,
        storePickupSchedule,
        email: email,
        deliveryPrice: deliveryPrice,
        ruta,
        descuento
      };
    } else {
      body = {
        name: name.replace('\'', '`'),
        direction: direction,
        province: province,
        city: city,
        country: country,
        observations: observations,
        articles: articles,
        phone: phone,
        deliveryDate: deliveryDate,
        storePickup: storePickup,
        storePickupSchedule,
        email: email,
        deliveryPrice: deliveryPrice,
        ruta,
        descuento
      };
    }

    return this.httpClient.post(this.environmentUrl + endpoints.pendingOrder, body);
  }

  getMinOrderPrice() {
    return this.httpClient.get(this.environmentUrl + endpoints.minOrderPrice);
  }

  getDeliveryTime(superGroup: string) {
    return this.httpClient.get(this.environmentUrl + endpoints.getDeliveryTime + '/' + superGroup);
  }

  getPendingArticleQuantity(article) {
    return this.httpClient.get(this.environmentUrl + endpoints.getPendingArticleQuantity + '/' + article, {});
  }

  deleteAllArticles() {
    return this.httpClient.get(this.environmentUrl + endpoints.deleteAllArticles, {});
  }

  articuloRegalo() {
    return this.httpClient.get(this.environmentUrl + endpoints.articuloRegalo);
  }
}

