import {SafeUrl} from '@angular/platform-browser';

export class Group {
  id: string;
  superGroupId: string;
  name: string;
  icon: string;
  img: SafeUrl;
  groupImgUrl: string;
}

export class SuperGroup {
  id: string;
  name: string;
  img: SafeUrl;
  icon: string;
  hide: boolean;
  deliveryDays: string;
  minOrderDate: string;
  groups: Array<Group>;
}
