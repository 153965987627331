import {Component, OnInit} from '@angular/core';
import {CompanyInfo} from '../../../interfaces/company-conf';

@Component({
  selector: 'app-legal-notice',
  templateUrl: './legal-notice.component.html',
  styleUrls: ['./legal-notice.component.css']
})
export class LegalNoticeComponent{

  province: string;
  datosLegalesEmpresa: CompanyInfo;
}
