import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AppConfig} from '../../../app.config';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WebEstaticaService {

  constructor(private httpClient: HttpClient, private config: AppConfig) {}

  public getPosicionamiento(): Observable<any> {
    return this.httpClient.get("assets/posicionamiento.json");
  }

  public getInicioJSON(): Observable<any> {
    return this.httpClient.get("assets/web-estatica/inicio/json/inicio.json");
  }

  public getContactoJSON(): Observable<any> {
    return this.httpClient.get("assets/web-estatica/inicio/json/contacto.json");
  }

  public getAcercaDeJSON(): Observable<any> {
    return this.httpClient.get("assets/web-estatica/inicio/json/acerca-de.json");
  }

  getPoliticasJSON(): Observable<any> {
    return this.httpClient.get("assets/web-estatica/inicio/json/politicas.json");
  }

  public getClubJSON(): Observable<any> {
    return this.httpClient.get("assets/web-estatica/inicio/json/club.json");
  }

  public getBarraSuperiorJSON(): Observable<any> {
    return this.httpClient.get("assets/web-estatica/inicio/json/barra-superior.json");
  }

  public getEncuestaJSON(): Observable<any> {
    return this.httpClient.get("assets/web-estatica/inicio/json/encuesta.json");
  }
}
