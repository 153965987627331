<div style="min-height: 100vh">
  <div class="container" *ngIf="datosLegalesEmpresa">
    <h3 class="pt-4 mb-3"><a routerLink="/"><i class="fas fa-arrow-left"></i> Volver</a></h3>
    <h1><b>Aviso de Cookies</b></h1>
    <p>Utilizamos cookies y herramientas similares (conjuntamente, “cookies”) para los fines descritos a continuación.</p>
    <div><b>Cookies operativas:</b> Cookies operativas:</div>
    <ul>
      <li>Reconocer cuándo te registras para usar nuestros servicios.</li>
      <li>Reconocerte como miembro de {{datosLegalesEmpresa.nameR}} y
        ofrecerte otras funcionalidades y servicios personalizados.</li>
      <li>Mantener un registro de los productos guardados en tu cesta.</li>
      <li>Mejorar la seguridad.</li>
    </ul>

    <h2><i><u>Información adicional</u></i></h2>
    <p>Las cookies operativas permanecerán en tu navegador durante 1 año. En el caso de cerrar sesión
      estas se eliminan automáticamente de tu navegador.</p>
    <p>Las cookies operativas te permitirán aprovechar algunas de las funcionalidades esenciales de
      {{datosLegalesEmpresa.nameR}}. Si bloqueas o rechazas de cualquier otra forma cookies operativas
      a través de los ajustes de tu navegador, es posible que algunas funcionalidades y
      servicios no funcionen. Por ejemplo, no podrás añadir elementos a tu cesta,
      tramitar pedidos o usar cualquier servicio de {{datosLegalesEmpresa.nameR}} que requiera que inicies sesión.
      Es posible que debas ajustar manualmente algunas de tus preferencias cada vez que
      utilices nuestros servicios.
    </p>
    <p>Consulta nuestro Aviso de Privacidad para más información sobre los tipos de información que recabamos.</p>

  </div>
</div>
