import { BrowserModule } from '@angular/platform-browser';
import {CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule} from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {HashLocationStrategy, LocationStrategy, PathLocationStrategy, registerLocaleData} from '@angular/common';
import { DatePipe } from '@angular/common';

// Import routing module
import {AppRoutingModule, routes} from './app.routing';

import { AppComponent } from './app.component';


// Data picker
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {DateAdapter, MAT_DATE_LOCALE} from '@angular/material/core';
import {RouterModule} from '@angular/router';
import { APP_INITIALIZER } from '@angular/core';


import {NgxPaginationModule} from 'ngx-pagination'; // <-- import the module

import { MDBBootstrapModule} from 'angular-bootstrap-md';
import {AuthInterceptorService} from './@core/services/auth/auth-interceptor.service';

import { AppConfig } from './app.config';
import {MyDateAdapter} from './custom.date.adapter';
import {SafePipe} from './@core/Pipes/safePipe';

import localeEs from '@angular/common/locales/es';

registerLocaleData(localeEs);
@NgModule({
    declarations: [
        AppComponent,
        SafePipe
    ],
    imports: [
        BrowserModule.withServerTransition({appId: 'serverApp'}),
        HttpClientModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        MDBBootstrapModule.forRoot(),
        NgxPaginationModule,
        RouterModule.forRoot(routes, {useHash: true}),
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    bootstrap: [AppComponent],
    providers: [
        DatePipe,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptorService,
            multi: true
        },
        AppConfig,
        {provide: APP_INITIALIZER, useFactory: (config: AppConfig) => () => config.load(), deps: [AppConfig], multi: true},
        {provide: DateAdapter, useClass: MyDateAdapter},
        {provide: MAT_DATE_LOCALE, useValue: 'es-ES'},
        {provide: LOCALE_ID, useValue: 'es-ES'},
        {provide: MAT_DATE_LOCALE, useValue: 'es-ES'},
        {provide: LocationStrategy, useClass: HashLocationStrategy},
        {provide: LocationStrategy, useClass: PathLocationStrategy},
    ]
})

export class AppModule { }
