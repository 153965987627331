import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AppConfig} from '../../../../app.config';
import {endpoints} from '../../../../../environments/endpoints';

@Injectable({
  providedIn: 'root'
})
export class AlbaranesService {

  environmentUrl;
  constructor(private httpClient: HttpClient, private config: AppConfig) {
    this.environmentUrl = this.config.getConfig('host');
  }

  obtenerAlbaranDePedidoPdf(cliente: string, centro: string, fecha: string, pedido: string) {
    return this.httpClient.get(this.environmentUrl + endpoints.obtenerAlbaranDePedidoPdf + '/' + cliente + '/' + centro + '/' + fecha + '/' + pedido, { responseType: 'blob' });
  }

  obtenerFacturaDesdePedidoPdf(centro: string, fecha: string, pedido: string) {
    return this.httpClient.get(this.environmentUrl + endpoints.obtenerFacturaDesdePedidoPdf + '/' + centro + '/' + fecha + '/' + pedido, { responseType: 'blob' });
  }

  obtenerAlbaranPdf(centro: string, fecha: string, pedido: number) {
      return this.httpClient.get(this.environmentUrl + endpoints.obtenerAlbaranClientePdf + '/' + centro + '/' + fecha + '/' + pedido, { responseType: 'blob' });
  }

  obtenerAlbaranDePedidoBits(cliente: string, centro: string, fecha: string, pedido: string) {
    return this.httpClient.get(this.environmentUrl + endpoints.obtenerAlbaranDePedidoBits + '/' + cliente + '/' + centro + '/' + fecha + '/' + pedido);
  }

  obtenerAlbaranesCliente() {
    return this.httpClient.get(this.environmentUrl + endpoints.obtenerAlbaranesCliente);
  }


  obtenerAlbaranCliente(centro: string, fecha: string, pedido: string) {
    return this.httpClient.post(this.environmentUrl + endpoints.obtenerAlbaranCliente, {
      center: centro,
      date: fecha,
      id: pedido
    });
  }

  enviarAlbaranMail(centro: string, fecha: string, id: string, email: string) {
    return this.httpClient.post(this.environmentUrl + endpoints.enviarAlbaranMail, {
      centro,
      fecha,
      id,
      email
    });
  }

  obtenerAlbaranesNoFacturados() {
    return this.httpClient.get(this.environmentUrl + endpoints.obtenerAlbaranesNoFacturados);
  }
}
