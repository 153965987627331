<div *ngIf="directions == null"
     class="align-items-center d-flex justify-content-center container-load">
  <div>
    <div class="w-100 d-flex justify-content-center mb-3">
      <img src="assets/loading/loading.gif" alt="Cargando icono" style="height: 150px !important; width: auto !important;"/>
    </div>
    <div class="text-center">
      <h3>Estamos cargando todas sus direcciones</h3>
      <p style="font-size: 14px">Espere un momento</p>
    </div>
  </div>
</div>

<div class="container" style="margin-bottom: 1.25em">
  <h3>
    <span>
      <a routerLink="/miPerfil">Mi Perfil</a> > Mis direcciones
    </span>
  </h3>
  <h1 class="font-weight-bold">Mis direcciones</h1>
  <div class="row">
    <!-- ADD DIRECTION -->
    <div class="col-md-4">
      <div class="card btn-light max-height text-black-50 d-flex justify-content-center
                  border-add" routerLink="/miPerfil/direcciones/nuevaDireccion">
        <div>
          <div class="d-flex justify-content-center">
            <div class="text-center">
              <i class="fas fa-plus fa-4x"></i>
              <h1 class="font-weight-bold text-black-50">Añadir dirección</h1>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-4">
      <div class="card" *ngIf="directionPostal">
        <div class="card-header"
             *ngIf="directionPostal.isInRoute == 1">Dirección Postal</div>
        <div class="card-header bg-red text-white"
             *ngIf="directionPostal.isInRoute == 0">Dirección Postal - Entrega no disponible</div>
        <div class="card-body" *ngIf="directionPostal">
          <div class="info-direction">
            <div>{{directionPostal.direction}}</div>
            <div>{{directionPostal.provinceName}}, {{directionPostal.cityName}},
              {{directionPostal.province}}{{directionPostal.city}}</div>
            <div>{{directionPostal.countryName}}</div>
            <div  *ngIf="directionPostal.phone">Número de teléfono: {{directionPostal.phone}}</div>
          </div>
          <div>
            <button class="border-0 bg-white text-info"
                    routerLink="/miPerfil/direcciones/nuevaDireccion" [state]="{ data: directionPostal, postal: true}">Editar</button>
          </div>
        </div>
      </div>
    </div>

    <div *ngFor="let direction of directions" class="col-md-4">
      <div class="card max-height">
        <div class="card-header bg-white" *ngIf="direction.isInRoute == 1">Dirección</div>
        <div class="card-header bg-red text-white" *ngIf="direction.isInRoute == 0">
          <b>Dirección</b> - Entrega no disponible
        </div>
        <div class="card-body">
          <div class="info-direction">
            <div>{{direction.direction}}</div>
            <div>{{direction.provinceName}}, {{direction.cityName}}, {{direction.province}}{{direction.city}}</div>
            <div>{{direction.countryName}}</div>
            <div *ngIf="direction.phone">Número de teléfono: {{direction.phone}}</div>
          </div>
          <div>
            <button class="border-0 bg-white text-info"
                    routerLink="/miPerfil/direcciones/nuevaDireccion" [state]="{ data: direction}">Editar</button>
            <span> | </span>
            <button class="border-0 bg-white text-info"
                    (click)="open(deleteDirection, direction)">Descartar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #deleteDirection let-modal class="modal">
  <div class="modal-header bg-red text-white">
    <h3 style="margin-bottom: 0">Confirmar eliminación</h3>
    <div class="max d-flex justify-content-end">
      <div class="text-white" (click)="modal.close()">
        <i class="fas fa-times-circle fa-2x"></i>
      </div>
    </div>
  </div>
  <div class="modal-body">
    <div>
      <div>{{selectedDirection.direction}}</div>
      <div>{{selectedDirection.provinceName}}, {{selectedDirection.cityName}}
        {{selectedDirection.province}}{{selectedDirection.city}}</div>
      <div>{{selectedDirection.countryName}}</div>
      <div *ngIf="selectedDirection.phone">Número de teléfono: {{selectedDirection.phone}}</div>
    </div>
    <span id="note">
      <br/><b>Nota:</b> Al eliminar esta dirección, no se eliminarán los pedidos que estén pendientes de ser enviados a esta dirección.
    </span>

    <div class="max-width d-flex justify-content-center" id="container-btn">
      <button class="btn btn-dark btn-delete" (click)="modal.close()">No</button>
      <button class="btn btn-primary text-light btn-delete" (click)="deleteUserDirection(modal)">Si</button>
    </div>
  </div>
</ng-template>
