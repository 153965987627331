import {Component, Input, OnInit} from '@angular/core';
import {UserInfo} from '../../../interfaces/user-info';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AuthService} from '../../../services/auth/auth.service';
import {AbstractControl, FormControl, FormGroup, ValidatorFn, Validators} from '@angular/forms';

declare let cordova: any;
declare let window: any;
@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.scss']
})
export class SetPasswordComponent implements OnInit {
  @Input() user: UserInfo;
  passwordForm = new FormGroup({
    oldPassword: new FormControl('', [Validators.required, Validators.minLength(4),
      Validators.maxLength(10)]),
    password: new FormControl('', [Validators.required, Validators.minLength(4),
      Validators.maxLength(10), this.notSamePassword()]),
    password_confirmation: new FormControl('', [Validators.required]),
  });
  incorrectPassword;
  notSame = false;
  hide0 = true;
  hide1 = true;
  hide2 = true;

  constructor(private modalService: NgbModal, private authService: AuthService) { }

  ngOnInit(): void {
    this.incorrectPassword = null;
  }

  setPasswordFn(content) {
    this.notSame = false;
    if (this.passwordForm.value.password !== this.passwordForm.value.password_confirmation) {
      this.notSame = true;
    } else {
      this.authService.setPassword(this.passwordForm.value.oldPassword, this.passwordForm.value.password).subscribe(
        () => {
          this.modalService.open(content, {backdropClass: 'light-grey-backdrop', centered: true});
        },
        error => {
          if (error.error.error === 'La contraseña no es correcta') {
            this.incorrectPassword = 'La contraseña no es correcta';
          }
        }
      );
    }

  }

  openExternalLink(link: string) {
    if (window.cordova && cordova.platformId !== "browser") {
      cordova.InAppBrowser.open(link, '_system');
    } else {
      window.open(link, '_blank', 'location=yes');
    }
  }

  // ----------------------------Validation functions ------------------------------
  notSamePassword(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      if (this.passwordForm != null) {
        if (control.value === this.passwordForm.value.oldPassword) {
          return {'notSamePasswordIncorrect': {value: control.value}};
        }
      }
      return null;
    };
  }

}
