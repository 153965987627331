import {ChangeDetectorRef, Component, ElementRef, Input, ViewChild} from '@angular/core';
import {Article} from '../../../interfaces/article';
import {DefaultService} from '../../../services/default.service';
import {ActivatedRoute, Router} from '@angular/router';
import {DomSanitizer} from '@angular/platform-browser';
import {CompanyConf} from '../../../interfaces/company-conf';

@Component({
  selector: 'app-carousel-article',
  templateUrl: './carousel-article.component.html',
  styleUrls: ['./carousel-article.component.scss']
})
export class CarouselArticleComponent {

  constructor(private defaultService: DefaultService, private route: ActivatedRoute, private _cdr: ChangeDetectorRef,
              private sanitizer: DomSanitizer, private router: Router) { }
  @Input() articles: Array<Article>;
  @Input() article: Article;
  @Input() companyConf: CompanyConf;
  @Input() paymentMethod: string;
  contentHeight: number = 200;
  @ViewChild('CONTENT') elementView: ElementRef;

  getNewArticle(article: Article) {
    let nombreArticulo = this.article.name.toLowerCase().split(' ').join('-').split('(').join('').split(')').join('').split('/').join('');
    let nombreGrupo = this.article.groupName.toLowerCase().split(' ').join('-').split('(').join('').split(')').join('').split('/').join('');
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() =>
      this.router.navigate([
        '/compra/' + nombreGrupo + '/' + nombreArticulo
      ], {
        queryParams: { grupo: article.group, familia: article.family, id: article.id }
      }));
  }
}
