import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AppConfig} from '../../../app.config';
import {endpoints} from '../../../../environments/endpoints';
import {Email} from '../../interfaces/email';

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  environmentUrl;
  constructor(private httpClient: HttpClient, private config: AppConfig) {
    this.environmentUrl = this.config.getConfig('host');
  }

  sendMail(email: Email) {
    return this.httpClient.post(this.environmentUrl + endpoints.sendContactMail,  email);
  }
}
