import {Component, Inject, OnInit, Renderer2} from '@angular/core';
import {DefaultService} from './@core/services/default.service';
import {CompanyInit} from './@core/interfaces/company-init';
import {NavigationEnd, Router} from '@angular/router';
import {DOCUMENT, Location} from '@angular/common';
import {LocalStorageService} from './@core/services/local-storage.service';
import {AuthStorageService} from './@core/services/auth/auth-storage.service';
import {AuthService} from './@core/services/auth/auth.service';
import {CompanyConf, CompanyInfo} from './@core/interfaces/company-conf';
import {PrivacyPolicyComponent} from './@core/components/footer/privacy-policy/privacy-policy.component';
import {LegalNoticeComponent} from './@core/components/footer/legal-notice/legal-notice.component';
import {CookiesComponent} from './@core/components/footer/cookies/cookies.component';
import {CssAttributes} from './@core/interfaces/CssAttributes';
import {Meta, Title} from '@angular/platform-browser';
import {DefaultContainerComponent} from './container/default-container/default-container.component';
import {WebEstaticaService} from './@core/services/web-estatica/web-estatica.service';
import {Posicionamiento} from './@core/interfaces/posicionamiento';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit{
  constructor(private defaultService: DefaultService, private router: Router, private location: Location,
              private localStorageService: LocalStorageService, private authStorageService: AuthStorageService,
              private authService: AuthService, private titleService:Title, private webEstaticaService: WebEstaticaService,
              private metaService: Meta, @Inject(DOCUMENT) private _document: Document,
              private _renderer2: Renderer2) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (this.posicionamiento != undefined) {
          this.cargarPosicionamiento();
        }
      }
    });
  }
  connection = true;
  cargadoPosicionamiento = false;
  name = '';
  loading = true;
  link: string;

  tokenExpires;
  interval: NodeJS.Timer | undefined;
  tokenDateTime: Date | undefined;

  datosLegalesEmpresa: CompanyInfo;
  datosBasicos: CompanyInfo;
  companyConf: CompanyConf;
  posicionamiento: Posicionamiento | undefined;
  fontUrl: string | undefined;
  mostrarFondo = false;

  ngOnInit(): void {
    this.agregarPosicionamiento();

    this.refreshToken();
    this.interval = setInterval(() => {
      this.refreshToken();
    }, 300000); // 300000

    this.getCompanyData();

    this.defaultService.getSCSSAttributes().subscribe((data: CssAttributes) => {
      this._document.documentElement.style.setProperty('--primary-color', data.primary);
      this._document.documentElement.style.setProperty('--secondary-color', data.secondary);
      this._document.documentElement.style.setProperty('--danger-color', data.danger);
      this._document.documentElement.style.setProperty('--font-style', data.font);
      this._document.documentElement.style.setProperty('--text-color', data.textPrimary);
      this._document.documentElement.style.setProperty('--text-secondary', data.textSecondary);
      this._document.documentElement.style.setProperty('--background-color', data.colorFondo);
      this.fontUrl = data.fontUrl;
      this.mostrarFondo = data.mostrarFondo;
      this._document.documentElement.style.setProperty('--font', data.font);
    });

  }

  validateUser() {
    this.authService.validateToken().subscribe(
      (result: any) => {
        const date = result.value.split(" ")[0].split("/");
        const time = result.value.split(" ")[1].split(":");
        this.tokenDateTime = new Date(date[2], date[1]-1, date[0], time[0], time[1], time[2]);
      },
      () => {this.authStorageService.logOut();}
    );
  }

  refreshToken() {
    if (this.authStorageService.getUser() != null) {
      if (this.tokenDateTime == undefined) {
        this.validateUser();
      } else {
        const now = new Date();
        const oneHour = new Date(this.tokenDateTime.getTime() - (60*60000));
        if (now > oneHour && now < this.tokenDateTime) {
          this.authService.refreshToken().subscribe(
            (result: any) => {
              this.authStorageService.setToken(result.token);
              const date = result.datetime.split(" ")[0].split("/");
              const time = result.datetime.split(" ")[1].split(":");
              this.tokenDateTime = new Date(date[2], date[1]-1, date[0], time[0], time[1], time[2]);
            }
          )
        }
      }
    }
  }

  getCompanyData() {
    this.defaultService.validConnection().subscribe(
      (data: CompanyInit) => {
        this.name = data.name;
        this.localStorageService.setCompanyType(data.companyType);

        if (data.companyType === 'E' && this.authStorageService.getUser() == null) {
          this.link = '/iniciarSesion';
        }

        if (data.paginaEstatica) {
          this.link = '/';
        } else if (data.initRoute === 'of' || data.initRoute === '') {
          this.link = '/compra/ofertas';
        } else if (data.initRoute === 'lista-de-articulos') {
          this.link = '/compra/lista-de-articulos';
        } else if (data.initRoute === 'repetir-pedido') {
          this.link = '/repetir-pedido';
        } else if (data.initRoute.substr(0, 1) === 'g') {
          this.link = '/compra/none/' + data.initRoute.substr(1, data.initRoute.length - 1);
        } else {
          this.link = '/compra/' + data.initRoute.substr(1, data.initRoute.length - 1) + '/*';
        }

        if (this.location.path() === '/' || this.location.path() === '/compra' || this.location.path() === '') {
          this.router.navigateByUrl(this.link).then();
        } else {
        }

        this.localStorageService.setDefaultLink(this.link);

        this.defaultService.getBasicCompanyConfig().subscribe((datosBasicos: CompanyInfo) => {
          this.datosBasicos = datosBasicos;
          this.loading = false;
        });
      },
      () => {
        this.loading = false;
        this.connection = false;
      });


  }

  onChildLoaded(component: PrivacyPolicyComponent | LegalNoticeComponent | CookiesComponent | DefaultContainerComponent) {
    if (component instanceof PrivacyPolicyComponent) {
      if (this.datosLegalesEmpresa == null) {
        this.defaultService.getCompanyInfo().subscribe((data: CompanyInfo) => {
          this.datosLegalesEmpresa = data;
          component.datosLegalesEmpresa = this.datosLegalesEmpresa;
        });
      } else {
        component.datosLegalesEmpresa = this.datosLegalesEmpresa;
      }

      if (this.companyConf == null) {
        this.defaultService.getCompanyConfig().subscribe((data: CompanyConf) => {
          this.companyConf = data;
          component.companyConf = this.companyConf;
        });
      } else {
        component.companyConf = this.companyConf;
      }
    } else if (component instanceof LegalNoticeComponent){
      if (this.datosLegalesEmpresa == null) {
        this.defaultService.getCompanyInfo().subscribe((data: CompanyInfo) => {
          this.datosLegalesEmpresa = data;
          component.datosLegalesEmpresa = this.datosLegalesEmpresa;
          component.province = this.datosLegalesEmpresa.direction.split(',')[1];
        });
      } else {
        component.datosLegalesEmpresa = this.datosLegalesEmpresa;
        component.province = this.datosLegalesEmpresa.direction.split(',')[1];
      }
    } else if (component instanceof CookiesComponent){
      if (this.datosLegalesEmpresa == null) {
        this.defaultService.getCompanyInfo().subscribe((data: CompanyInfo) => {
          this.datosLegalesEmpresa = data;
          component.datosLegalesEmpresa = this.datosLegalesEmpresa;
        });
      } else {
        component.datosLegalesEmpresa = this.datosLegalesEmpresa;
      }
    } else if (component instanceof DefaultContainerComponent){
      component.datosBasicos = this.datosBasicos;
      component.posicionamiento = this.posicionamiento;

      if (this.datosLegalesEmpresa == null) {
        this.defaultService.getCompanyInfo().subscribe((data: CompanyInfo) => {
          this.datosLegalesEmpresa = data;
          component.datosLegalesEmpresa = this.datosLegalesEmpresa;
        });
      } else {
        component.datosLegalesEmpresa = this.datosLegalesEmpresa;
      }

      if (this.companyConf == null) {
        this.defaultService.getCompanyConfig().subscribe((data: CompanyConf) => {
          this.companyConf = data;
          component.companyConf = this.companyConf;

          component.checkUser();
          component.inicializarInfo();
        });
      } else {
        component.companyConf = this.companyConf;

        component.checkUser();
        component.inicializarInfo();
      }
    }
  }

  agregarPosicionamiento() {
    this.webEstaticaService.getPosicionamiento().subscribe(posicionamiento => {
      this.posicionamiento = posicionamiento;
      this.cargarPosicionamiento();
    });
  }

  cargarPosicionamiento() {
    this.limpiarPosicionamiento();
    if (this.posicionamiento != undefined) {
      if (this.router.url.includes('/compra/ofertas')) {
        this.cargarPosicionamientoOfertas();
      } else if (this.router.url.includes('/compra/buscar')) {
        this.cargarPosicionamientoBuscar();
      } else if (this.router.url.includes('/compra')) {
        let ruta = this.router.url;
        ruta = ruta.replace('/compra/', '')
        let rutas = ruta.split('?');
        if (rutas.length > 0) {
          ruta = rutas[0];
          rutas = ruta.split('/');
          if (rutas.length == 1) { // super grupo o grupo
            this.cargarPosicionamientoArticulos(rutas[0]);
          } else if (rutas.length == 2) { // articulo
            this.cargarPosicionamientoArticulo(rutas[0], rutas[1])
          }
        }
      } else {
        let title = this.posicionamiento.Title;
        let descripcion = this.posicionamiento.Description;

        if (this.router.url.includes('acerca-de')) {
          title = 'Conoce nuestra historia | ' + this.posicionamiento.Title;
        } else if (this.router.url.includes('atencion-al-cliente')) {
          title = '¡Conócenos! Contacta con Nosotros' + this.posicionamiento.Title;
          descripcion = "¿Tienes preguntas o comentarios? Estamos aquí para ayudarte. Contacta con nosotros de manera fácil y rápida." +
                        " Nuestro equipo de atención al cliente está listo para responder tus consultas y brindarte asistencia. " +
                        "¡No dudes en ponerte en contacto con nosotros hoy mismo!"
        } else if (this.router.url.includes('iniciarSesion')) {
          title = 'Inicia Sesión | ' + this.posicionamiento.Title;
          descripcion = "Accede a tu cuenta de manera segura y rápida. Inicia sesión en nuestra plataforma y disfruta de una experiencia " +
                        "personalizada. Gestiona tus datos y disfruta de nuestros servicios con un solo clic. ¡Bienvenido de vuelta!"
        } else if (this.router.url.includes('registrarse')) {
          title = 'Registrate con nosotros | ' + this.posicionamiento.Title;
          descripcion = "Únete a nuestra comunidad y regístrate en minutos. Crea tu cuenta personalizada y disfruta de múltiples ventajas. " +
                         "¡Empieza tu viaje con nosotros hoy mismo y descubre todas las ventajas que tenemos para ofrecerte!"
        } else if (this.router.url.includes('carrito')) {
          title = 'Tramita tu pedido | ' + this.posicionamiento.Title;
          descripcion = "Completa tu pedido de forma sencilla y rápida. Inicia el proceso de tramitación de tu compra en nuestra plataforma" +
                        " y disfruta de nuestros productos y servicios de calidad. " +
                        "¡Haz tu pedido ahora y experimenta la comodidad de comprar con nosotros!"
        } else if (this.router.url.includes('aviso-legal')) {
          title = 'Aviso Legal | ' + this.posicionamiento.Title;
          descripcion = "Descubre nuestros términos y condiciones legales. Lee nuestro aviso legal para conocer tus derechos " +
            "y responsabilidades al utilizar nuestros servicios. Información importante sobre privacidad, cookies y más.";
        } else if (this.router.url.includes('politica-privacidad')) {
          title = 'Política de privacidad | ' + this.posicionamiento.Title;
          descripcion = "Lee nuestra política de privacidad para proteger tus datos personales. Descubre cómo cuidamos tu información" +
                        " y tu privacidad mientras disfrutas de nuestros servicios. Tu confianza es nuestra prioridad.";
        } else if (this.router.url.includes('política-devolucion-y-envio')) {
          title = 'Política de devoluciones y envíos | ' + this.posicionamiento.Title;
          descripcion = "Conoce nuestra política de devolución y envío. Obtén información detallada sobre cómo gestionamos tus pedidos y " +
                        "devoluciones. Tu satisfacción es nuestra prioridad, descubre cómo cuidamos de tus compras.";
        } else if (this.router.url.includes('aviso-cookies')) {
          title = 'Información sobre Cookies | ' + this.posicionamiento.Title;
          descripcion = "Consulta nuestro aviso de cookies para entender cómo utilizamos las cookies y tecnologías similares en nuestro " +
                        "sitio web. Aprende cómo estas herramientas mejoran tu experiencia de usuario y privacidad en línea.";
        }
        this.titleService.setTitle(title);
        this.metaService.addTag({name: 'description', content: descripcion});
        this.metaService.addTag({name: 'og:title', content: title});
        this.metaService.addTag({name: 'og:locale', content: this.posicionamiento.Locale});
        this.metaService.addTag({name: 'og:url', content: this._document.URL});
        this.metaService.addTag({name: 'keywords', content: this.posicionamiento.KeyWords});
        this.metaService.addTag({name: 'og:site_name', content: this.posicionamiento.Name});
        this.metaService.addTag({name: 'name', content: this.posicionamiento.Name});
      }
    }
    this.metaService.addTag({name: 'author', content: 'Soft Control Informática, S.A.'});
    this.metaService.addTag({name: 'copyright', content: '© 2023, Soft Control Informática, S.A.'});
    this.metaService.addTag({name: 'robots', content: 'index, follow'});
    this.metaService.addTag({name: 'googlebot', content: 'index, follow'});

    const els = this._document.querySelectorAll('link[rel=\'canonical\']');
    for (let i = 0, l = els.length; i < l; i++) {
      const el = els[i];
      el.remove();
    }

    let link: HTMLLinkElement = this._document.createElement('link');
    link.setAttribute('rel', 'canonical');
    this._document.head.appendChild(link);
    let url = this._document.URL;
    if (url.includes('inicio')) {
      url = url.replace('/', '');
    }
    link.setAttribute('href', url);
  }


  cargarPosicionamientoOfertas() {
    let keyWords = 'ofertas,descuentos,promociones,mejores ofertas,precios bajos,' + this.posicionamiento.KeyWords;
    this.titleService.setTitle(this.posicionamiento.Title + ' | Ofertas de productos');

    this.metaService.addTag({ name: 'description', content: '¡Descubre nuestra amplia selección de productos en oferta de alta calidad! ' +
        ' Realiza tu pedido hoy mismo y disfruta de nuestros productos.' });
    this.metaService.addTag({ name: 'keywords', content: keyWords});
    this.metaService.addTag({ name: 'og:site_name', content: this.posicionamiento.Name });
    this.metaService.addTag({ name: 'robots', content: this.posicionamiento.Robots });
    this.metaService.addTag({ name: 'og:locale', content: this.posicionamiento.Locale });
  }

  cargarPosicionamientoBuscar() {
    let keyWords = 'buscar,buscar todos,variedad,' + this.posicionamiento.KeyWords;
    this.titleService.setTitle(this.posicionamiento.Title + ' | Buscar productos');

    this.metaService.addTag({ name: 'description', content: '¡Descubre nuestra amplia selección de productos de alta calidad! ' +
        ' Realiza tu pedido hoy mismo y disfruta de nuestros productos.' });
    this.metaService.addTag({ name: 'keywords', content: keyWords});
    this.metaService.addTag({ name: 'og:site_name', content: this.posicionamiento.Name });
    this.metaService.addTag({ name: 'robots', content: this.posicionamiento.Robots });
    this.metaService.addTag({ name: 'og:locale', content: this.posicionamiento.Locale });
  }

  cargarPosicionamientoArticulos(nombreGrupo: string) {
    nombreGrupo = nombreGrupo.replace('-', ' ');
    let keyWords = nombreGrupo + ',' + this.posicionamiento.KeyWords;
    this.titleService.setTitle(nombreGrupo[0].toUpperCase() + nombreGrupo.substr(1).toLowerCase() + ' | ' + this.posicionamiento.Title );

    this.metaService.addTag({ name: 'description', content: '¡Descubre nuestra amplia selección de ' + nombreGrupo + ' de alta calidad! ' +
        ' Realiza tu pedido hoy mismo y disfruta de nuestros productos.' });
    this.metaService.addTag({ name: 'keywords', content: keyWords});
    this.metaService.addTag({ name: 'og:site_name', content: this.posicionamiento.Name });
    this.metaService.addTag({ name: 'robots', content: this.posicionamiento.Robots });
    this.metaService.addTag({ name: 'og:locale', content: this.posicionamiento.Locale });
  }

  cargarPosicionamientoArticulo(nombreGrupo: string, nombreArticulo: string) {
    nombreGrupo = nombreGrupo.replace('-', ' ');
    nombreArticulo = nombreGrupo.replace('-', ' ');
    this.limpiarPosicionamiento();
    this.titleService.setTitle(nombreArticulo[0].toUpperCase() + nombreArticulo.substr(1).toLowerCase() + ' | ' +  this.companyConf.webName);
    this.metaService.addTag({ name: 'description', content: 'Prueba nuestro delicioso ' + nombreArticulo + '. Con ingredientes frescos y de alta calidad, este ' + nombreArticulo + ' te sorprenderá con su sabor excepcional.  ¡Descubre una nueva experiencia gastronómica con ' + this.companyConf.webName + ' y deleita tu paladar hoy mismo!' });
    this.metaService.addTag({ name: 'og:type', content: 'product' });
    this.metaService.addTag({ name: 'product:price:currency', content: 'EUR' });
    this.metaService.addTag({ name: 'product:availability', content: 'availability' });
    this.metaService.addTag({ name: 'keywords', content: nombreArticulo + ',' + this.posicionamiento.Title + ',' + nombreGrupo + ',comprar,producto,kilos,cajas,unidades,mallas,docenas'});

  }

  limpiarPosicionamiento() {
    this.metaService.removeTag("name='og:title'");
    this.metaService.removeTag("name='description'");
    this.metaService.removeTag("name='og:site_name'");
    this.metaService.removeTag("name='og:url'");
    this.metaService.removeTag("name='name'");
    this.metaService.removeTag("name='author'");
    this.metaService.removeTag("name='keywords'");
    this.metaService.removeTag("name='robots'");
    this.metaService.removeTag("name='og:locale'");
    this.metaService.removeTag("name='og:type'");
    this.metaService.removeTag("name='og:image'");
    this.metaService.removeTag("name='product:price:currency'");
    this.metaService.removeTag("name='product:price:amount'");
    this.metaService.removeTag("name='product:availability'");
  }
}
