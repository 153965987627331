<div class="container" style="margin-bottom: 1.25em">
  <h3>
    <span>
      <a routerLink="/miPerfil">Mi Perfil</a> >
      <a routerLink="/miPerfil/direcciones">Mis direcciones</a> > Nueva dirección
    </span>
  </h3>
  <div class="row">
    <div class="col-md-3"></div>
    <div class="col-md-6">
      <div class="card">
        <div class="card-body">
          <h2 *ngIf="direction">Editar dirección
            <span class="text-danger" *ngIf="direction.isInRoute == 0"> - Entrega no disponible</span>
          </h2>
          <h2 *ngIf="!direction">Añadir nueva dirección</h2>

          <div *ngIf="errorDirection" class="error">{{errorDirection}}</div>
          <form (ngSubmit)="addDirection()" [formGroup]="userForm" novalidate>

            <div class="row select-container">
              <div class="col-sm-12">
                <div class="form-group">
                  <ng-select placeholder="País*"
                             formControlName="country"
                             (change)="getProvinces()">
                    <ng-option *ngFor="let country of countries" [value]="country.id">{{country.name}}</ng-option>
                  </ng-select>
                  <div *ngIf="userForm.controls.country.touched && userForm.controls.country.errors?.required"
                       class="error">Campo obligatorio.
                  </div>
                </div>
              </div>
            </div>

            <div class="row select-container">
              <div class="col-sm-12">
                <div class="form-group">
                  <ng-select placeholder="Provincia*"
                             formControlName="province"

                             (change)="getCitiesDefault()">
                    <ng-option *ngFor="let province of provinces" [value]="province.id">{{province.name}}</ng-option>
                  </ng-select>
                  <div *ngIf="userForm.controls.province.touched && userForm.controls.province.errors?.required"
                       class="error">Campo obligatorio.
                  </div>
                </div>
              </div>
            </div>

            <div class="row select-container">
              <div class="col-sm-12">
                <div class="form-group">
                  <ng-select placeholder="Población*"
                             formControlName="city"

                             (change)="setMsg()">
                    <ng-option value="default">Seleccione una ciudad</ng-option>
                    <ng-option *ngFor="let city of cities" [value]="city.id">{{city.name}},
                      {{userForm.controls.province.value}}{{city.id}}</ng-option>
                  </ng-select>
                  <div *ngIf="userForm.controls.city.touched &&
                            (userForm.controls.city.errors?.required || userForm.controls.city.errors?.defaultOption)"
                       class="error">Campo obligatorio.
                  </div>
                  <div *ngIf="msgInfoDays" class="text-info">
                    <div [innerHTML]="msgInfoDays"></div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-12">
                <mat-form-field style="width: 100%">
                  <mat-label>Dirección</mat-label>
                  <input type="text" class="w-100"
                         formControlName="direction"
                         placeholder="Ej: Calle Alcalá, 48, 1ºA"
                         matInput>
                  <div *ngIf="userForm.controls.direction.touched && userForm.controls.direction.errors?.required"
                       class="error">Campo obligatorio.
                  </div>
                </mat-form-field>
              </div>
            </div>

            <div class="row" *ngIf="!isPostal">
              <div class="col-sm-12">
                <mat-form-field style="width: 100%">
                  <mat-label>Teléfono</mat-label>
                  <input type="text" class="w-1º"
                         formControlName="phoneNumber" matInput>
                  <div *ngIf="userForm.controls.phoneNumber.touched && userForm.controls.phoneNumber.errors?.pattern"
                       class="error">Formato incorrecto.
                  </div>
                </mat-form-field>
              </div>
            </div>

            <!-- Submit button -->
            <button type="submit" class="btn btn-primary text-light btn-block"
                    [disabled]="!userForm.valid" *ngIf="!direction">
              Añadir dirección
            </button>

            <button type="submit" class="btn btn-primary text-light btn-block"
                    [disabled]="!userForm.valid || changeDirection() || userForm.controls.city.errors?.defaultOption" *ngIf="direction">
              Editar dirección
            </button>
          </form>
        </div>
      </div>
    </div>
    <div class="col-md-3"></div>
  </div>
</div>
