import {PriceRoute} from "./price-route";

export class Direction {
  country: string;
  countryName: string;
  province: string;
  provinceName: string;
  city: string;
  cityName: string;
  direction: string;
  phone: string;
  isInRoute: number;
  deliveryDays: string;
  priceRoute: PriceRoute;

  constructor(country: string, countryName: string, province: string,
              provinceName: string, city: string, cityName: string,
              direction: string, phone: string, deliveryDays: string, isInRoute: number) {
    this.country = country;
    this.countryName = countryName;
    this.province = province;
    this.provinceName = provinceName;
    this.city = city;
    this.cityName = cityName;
    this.direction = direction;
    this.phone = phone;
    this.deliveryDays = deliveryDays;
    this.isInRoute = isInRoute;
  }
}

export class DirectionText {
  value: string;
  deliveryDays: string;
  priceRoute: PriceRoute;
}
