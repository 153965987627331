
<section id="inicio" *ngIf="inicioJson && companyConf != undefined">
  <div class="container-fluid" *ngIf="companyConf.companyException == 'PEMA'; else ElseInicioNormal">
    <div class="w-100 text-center mb-3">
      <h1> {{inicioJson.titulo}}</h1>
    </div>

    <h2 class="text-primary w-100 text-center">NUESTRA PESCADERÍA EN DIRECTO</h2>
    <p>
      <iframe src="https://g0.ipcamlive.com/player/player.php?alias=60b126f497333" width="100%" height="600px"
              frameborder="0" allowfullscreen="allowfullscreen"></iframe>
    </p>
  </div>

  <ng-template #ElseInicioNormal>
    <div *ngIf="!inicioJson.soloFotos" [ngStyle]="{'background-image': 'url(assets/web-estatica/inicio/' + fotoSeleccionada + ')'}"
         style="min-height: 75vh !important; background-size: cover; background-position: center;"
         class="w-100 d-flex justify-content-center align-items-center">
      <div>
        <h3 style="font-size: 1.875em !important;">
          {{inicioJson.texto1}}
        </h3>
        <h2 style="font-size: 3.75em !important; line-height: 70px;" class="font-weight-bold">
          {{inicioJson.texto2}}
        </h2>
        <h2 style="font-size: 2.5em !important;" class="font-weight-bold">
          {{inicioJson.texto3}}
        </h2>
        <h3  style="font-size: 1.875em !important;" *ngIf="inicioJson.texto4 != ''">
          {{inicioJson.texto4}}
        </h3>
        <h4  style="font-size: 1.25em !important;" *ngIf="inicioJson.texto5 != ''">
          {{inicioJson.texto5}}
        </h4>
        <button class="btn btn-primary text-light font-weight-bold mt-2 p-2 rounded-button"
                routerLink="{{inicioJson.linkTienda}}">
          Comprar ahora
        </button>
      </div>
    </div>

    <div *ngIf="inicioJson.soloFotos" class="container-fluid mt-4 pointer"
         (click)="irARuta(inicioJson.rutas[counter].ruta, inicioJson.rutas[counter].posicion)">
      <img [src]="'assets/web-estatica/inicio/' + fotoSeleccionada" width="100%" height="auto" alt="">
    </div>
  </ng-template>
</section>

<section id="servicios"  *ngIf="inicioJson">
  <div class="container-fluid mt-5" *ngIf="inicioJson.nuestrosServicios">
    <div class="computer">
      <div class="row">
        <div class="col-md-3 border-right pt-3 pb-3 mb-1">
          <div class="d-flex justify-content-center w-100">
            <i class="fa-solid fa-truck-moving fa-3x text-secondary"></i>
            <div class="ml-3">
              <h2 class="font-weight-bold mb-0">Entrega a domicilio</h2>
              <h4 class="mb-0">A tu puerta</h4>
            </div>
          </div>
        </div>
        <div class="col-md-3 border-right pt-3 pb-3 mb-1">
          <div class="d-flex justify-content-center w-100">
            <i class="fa-solid fa-store fa-3x text-secondary"></i>
            <div class="ml-3">
              <h2 class="font-weight-bold mb-0">Entrega en tienda</h2>
              <h4 class="mb-0">Busca nuestras ubicaciones</h4>
            </div>
          </div>
        </div>
        <div class="col-md-3 border-right pt-3 pb-3 mb-1">
          <div class="d-flex justify-content-center w-100">
            <i class="fa-solid fa-headset fa-3x text-secondary"></i>
            <div class="ml-3">
              <h2 class="font-weight-bold mb-0">A tu disposición</h2>
              <h4 class="mb-0">Contacta con nosotros</h4>
            </div>
          </div>
        </div>
        <div class="col-md-3 pt-3 pb-3 mb-1">
          <div class="d-flex justify-content-center w-100">
            <i class="fa-solid fa-mobile-screen-button fa-3x text-secondary"></i>
            <div class="ml-3">
              <h2 class="font-weight-bold mb-0">Pide en camino</h2>
              <h4 class="mb-0">Descarga nuestra app</h4>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mobile">
      <div class="row">
        <div class="col-md-3 border-bottom pt-3 pb-3 mb-1">
          <div class="w-100 d-flex">
            <div class="text-center" style="width: 4.375em">
              <i class="fa-solid fa-truck-moving fa-3x text-secondary"></i>
            </div>
            <div class="ml-3">
              <h2 class="font-weight-bold mb-0">Entrega a domicilio</h2>
              <h4 class="mb-0">A tu puerta</h4>
            </div>
          </div>
        </div>
        <div class="col-md-3 border-bottom pt-3 pb-3 mb-1">
          <div class="w-100 d-flex">
            <div class="text-center" style="width: 4.375em">
              <i class="fa-solid fa-store fa-3x text-secondary"></i>
            </div>
            <div class="ml-3">
              <h2 class="font-weight-bold mb-0">Entrega en tienda</h2>
              <h4 class="mb-0">Busca nuestras ubicaciones</h4>
            </div>
          </div>
        </div>
        <div class="col-md-3 border-bottom pt-3 pb-3 mb-1">
          <div class="w-100 d-flex">
            <div class="text-center" style="width: 4.375em">
              <i class="fa-solid fa-headset fa-3x text-secondary"></i>
            </div>
            <div class="ml-3">
              <h2 class="font-weight-bold mb-0">A tu disposición</h2>
              <h4 class="mb-0">Contacta con nosotros</h4>
            </div>
          </div>
        </div>
        <div class="col-md-3 pt-3 pb-3 mb-1">
          <div class="w-100 d-flex">
            <div class="text-center" style="width: 4.375em">
              <i class="fa-solid fa-mobile-screen-button fa-3x text-secondary"></i>
            </div>

            <div class="ml-3">
              <h2 class="font-weight-bold mb-0">Pide en camino</h2>
              <h4 class="mb-0">Descarga nuestra app</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section id="redes-sociales"  *ngIf="inicioJson">
  <div class="container-fluid mt-5">
    <div class="computer">
      <div class="row">
        <div class="col-md-3 border-right pt-3 pb-3 mb-1 pointer"  *ngIf="inicioJson.instagram != ''">
          <a class="d-flex justify-content-center w-100 text-decoration-none" (click)="openExternalLink(inicioJson.instagram)">
            <i class="fa-brands fa-instagram fa-3x text-primary"></i>
          </a>
        </div>
        <div class="col-md-3 border-right pt-3 pb-3 mb-1 pointer" *ngIf="inicioJson.facebook != ''">
          <a class="d-flex justify-content-center w-100 text-decoration-none" (click)="openExternalLink(inicioJson.facebook)">
            <i class="fa-brands fa-facebook fa-3x text-primary"></i>
          </a>
        </div>
        <div class="col-md-3 pt-3 pb-3 mb-1 pointer" *ngIf="inicioJson.linkedin != ''">
          <a class="d-flex justify-content-center w-100 text-decoration-none" (click)="openExternalLink(inicioJson.linkedin)">
            <i class="fa-brands fa-linkedin fa-3x text-primary"></i>
          </a>
        </div>
        <div class="col-md-3 pt-3 pb-3 mb-1 pointer" *ngIf="inicioJson.tiktok != ''">
          <a class="d-flex justify-content-center w-100 text-decoration-none" (click)="openExternalLink(inicioJson.tiktok)">
            <i class="fa-brands fa-tiktok fa-3x text-primary"></i>
          </a>
        </div>
      </div>
    </div>

    <div class="mobile">
      <div class="row">
        <div class="col-4 pt-3 pb-3 mb-1 pointer"  *ngIf="inicioJson.instagram != ''">
          <a class="w-100 d-flex text-decoration-none" (click)="openExternalLink(inicioJson.instagram)">
            <div class="text-center" style="width: 4.375em">
              <i class="fa-brands fa-instagram fa-3x text-primary"></i>
            </div>
          </a>
        </div>
        <div class="col-4 pt-3 pb-3 mb-1 pointer"  *ngIf="inicioJson.facebook != ''">
          <a class="w-100 d-flex text-decoration-none" (click)="openExternalLink(inicioJson.facebook)">
            <div class="text-center" style="width: 4.375em">
              <i class="fa-brands fa-facebook fa-3x text-primary"></i>
            </div>
          </a>
        </div>
        <div class="col-4 pt-3 pb-3 mb-1 pointer"  *ngIf="inicioJson.linkedin != ''">
          <a class="w-100 d-flex text-decoration-none"  (click)="openExternalLink(inicioJson.linkedin)">
            <div class="text-center" style="width: 4.375em">
              <i class="fa-brands fa-linkedin fa-3x text-primary"></i>
            </div>
          </a>
        </div>
        <div class="col-4 pt-3 pb-3 mb-1 pointer " *ngIf="inicioJson.tiktok != ''">
          <a class="w-100 d-flex text-decoration-none"  (click)="openExternalLink(inicioJson.tiktok)">
            <div class="text-center" style="width: 4.375em">
              <i class="fa-solid fa-mobile-screen-button fa-3x text-primary"></i>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</section>

<section id="ofertas" *ngIf="articulosOferta">
  <div class="container-fluid mt-5">
    <h2 style="font-size: 2.5em !important;" class="font-weight-bold">Mejores ofertas</h2>
    <div id="carouselExampleControls" class="carousel slide mt-3" data-ride="carousel">
      <!-- Diapositivas -->
      <div class="carousel-inner">
        <div *ngFor="let item of [].constructor(paginasCarrousel + 1); let i = index" class="carousel-item"
             [ngClass]="{'active': posicionCarrousel == i}">
          <div class="row mx-auto my-auto w-100">
            <div class="col-lg-3 col-6 border pointer" *ngIf="articulosOferta.length > (i * 4)"
                 (click)="redirectToArticulo(articulosOferta[i * 4].groupName, articulosOferta[i * 4].name, articulosOferta[i * 4].group, articulosOferta[i * 4].family, articulosOferta[i * 4].id )">
              <div class="mb-3 mt-3 text-center font-weight-bold">
                <div>
                  <img *ngIf="articulosOferta[i * 4].articleImgUrl; else productImg0"
                       class="w-100"
                       src="./assets/fotos/articulos/{{articulosOferta[i * 4].articleImgUrl}}" alt="{{articulosOferta[i * 4].name}}"/>
                  <ng-template #productImg0>
                    <img *ngIf="articulosOferta[i * 4].productImgUrl"
                         class="w-100"
                         src="./assets/fotos/productos/{{articulosOferta[i * 4].productImgUrl}}" alt="{{articulosOferta[i * 4].name}}"/>
                  </ng-template>

                  <div>
                    <span class="w-100" *ngIf="articulosOferta[i * 4].nameClient != null">{{articulosOferta[i * 4].nameClient}}</span>
                    <span class="w-100" *ngIf="articulosOferta[i * 4].nameClient == null">{{articulosOferta[i * 4].name}}</span>
                    <div class="text-danger">{{articulosOferta[(i * 4)].priceIvaStr}} €</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-6 border pointer" *ngIf="articulosOferta.length > (i * 4) + 1"
                 (click)="redirectToArticulo(articulosOferta[(i * 4) + 1].groupName, articulosOferta[(i * 4) + 1].name, articulosOferta[(i * 4) + 1].group, articulosOferta[(i * 4) + 1].family, articulosOferta[(i * 4) + 1].id )">              <div class="mb-3 mt-2 text-center font-weight-bold">
              <div>
                <img *ngIf="articulosOferta[(i * 4) + 1].articleImgUrl; else productImg0"
                     class="w-100"
                     src="./assets/fotos/articulos/{{articulosOferta[(i * 4) + 1].articleImgUrl}}" alt="{{articulosOferta[(i * 4) + 1].name}}"/>
                <ng-template #productImg0>
                  <img *ngIf="articulosOferta[(i * 4) + 1].productImgUrl"
                       class="w-100"
                       src="./assets/fotos/productos/{{articulosOferta[(i * 4) + 1].productImgUrl}}" alt="{{articulosOferta[(i * 4) + 1].name}}"/>
                </ng-template>

                <div>
                  <span class="w-100" *ngIf="articulosOferta[(i * 4) + 1].nameClient != null">{{articulosOferta[(i * 4) + 1].nameClient}}</span>
                  <span class="w-100" *ngIf="articulosOferta[(i * 4) + 1].nameClient == null">{{articulosOferta[(i * 4) + 1].name}}</span>
                  <div class="text-danger">{{articulosOferta[(i * 4) + 1].priceIvaStr}} €</div>
                </div>
              </div>
            </div>
            </div>
            <div class="col-lg-3 col-6 border pointer" *ngIf="articulosOferta.length > (i * 4) + 2"
                 (click)="redirectToArticulo(articulosOferta[(i * 4) + 2].groupName, articulosOferta[(i * 4) + 2].name, articulosOferta[(i * 4) + 2].group, articulosOferta[(i * 4) + 2].family, articulosOferta[(i * 4) + 2].id )">
              <div class="mb-3 mt-2 text-center font-weight-bold">
                <div>
                  <img *ngIf="articulosOferta[(i * 4) + 2].articleImgUrl; else productImg0"
                       class="w-100"
                       src="./assets/fotos/articulos/{{articulosOferta[(i * 4) + 2].articleImgUrl}}" alt="{{articulosOferta[(i * 4) + 2].name}}"/>
                  <ng-template #productImg0>
                    <img *ngIf="articulosOferta[(i * 4) + 2].productImgUrl"
                         class="w-100"
                         src="./assets/fotos/productos/{{articulosOferta[(i * 4) + 2].productImgUrl}}"  alt="{{articulosOferta[(i * 4) + 2].name}}"/>
                  </ng-template>

                  <div>
                    <span class="w-100" *ngIf="articulosOferta[(i * 4) + 2].nameClient != null">{{articulosOferta[(i * 4) + 2].nameClient}}</span>
                    <span class="w-100" *ngIf="articulosOferta[(i * 4) + 2].nameClient == null">{{articulosOferta[(i * 4) + 2].name}}</span>
                    <div class="text-danger">{{articulosOferta[(i * 4) + 2].priceIvaStr}} €</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-6 border pointer" *ngIf="articulosOferta.length > (i * 4) + 3"
                 (click)="redirectToArticulo(articulosOferta[(i * 4) + 3].groupName, articulosOferta[(i * 4) + 3].name, articulosOferta[(i * 4) + 3].group, articulosOferta[(i * 4) + 3].family, articulosOferta[(i * 4) + 3].id )">              <div class="mb-3 mt-2 text-center font-weight-bold">
              <div>
                <img *ngIf="articulosOferta[(i * 4) + 3].articleImgUrl; else productImg0"
                     class="w-100"
                     src="./assets/fotos/articulos/{{articulosOferta[(i * 4) + 3].articleImgUrl}}"  alt="{{articulosOferta[(i * 4) + 3].name}}"/>
                <ng-template #productImg0>
                  <img *ngIf="articulosOferta[(i * 4) + 3].productImgUrl"
                       class="w-100"
                       src="./assets/fotos/productos/{{articulosOferta[(i * 4) + 3].productImgUrl}}"  alt="{{articulosOferta[(i * 4) + 3].name}}"/>
                </ng-template>

                <div>
                  <span class="w-100" *ngIf="articulosOferta[(i * 4) + 3].nameClient != null">{{articulosOferta[(i * 4) + 3].nameClient}}</span>
                  <span class="w-100" *ngIf="articulosOferta[(i * 4) + 3].nameClient == null">{{articulosOferta[(i * 4) + 3].name}}</span>
                  <div class="text-danger">{{articulosOferta[(i * 4) + 3].priceIvaStr}} €</div>
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Controles -->
      <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Anterior</span>
      </a>
      <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Siguiente</span>
      </a>
    </div>


    <div class="w-100 d-flex justify-content-center mt-3">
      <button class="btn btn-primary text-light font-weight-bold mt-2 p-2 rounded-button"
              routerLink="/compra/ofertas">
        Comprar mejores ofertas
      </button>
    </div>
  </div>
</section>


<section id="banners-ofertas" *ngIf="inicioJson">
  <div class="container-fluid mt-5">
    <div class="row">
      <div  *ngFor="let seccion of inicioJson.seccionesPublicidad" class="col-md-{{seccion.columnas}} mt-3">
        <div [ngStyle]="{'background-image': 'url(assets/web-estatica/inicio/' + seccion.imagen}"
             style="min-height: 50vh !important; background-size: cover;"
             class="w-100 d-flex align-items-center justify-content-center border p-2">
          <div>
            <h3 style="font-size: 1.25em !important;" class="font-weight-bold">
              {{seccion.texto1}}
            </h3>
            <h2 style="font-size: 1.875em !important;" class="font-weight-bold">
              {{seccion.texto2}}
            </h2>
            <h2 style="font-size: 3.75em !important; line-height: 70px !important;" class="font-weight-bold">
              {{seccion.texto3}}
            </h2>
            <h4 class="font-weight-bold">{{seccion.texto4}}</h4>
            <p class="font-weight-bold">{{seccion.texto5}}</p>
            <p class="font-weight-bold">{{seccion.texto6}}</p>

            <button class="btn btn-primary text-light font-weight-bold mt-2 p-2 rounded-button" (click)="redireccionarSeccion(seccion.link); windowTop()">
              {{seccion.textoBoton}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section id="categorias-populares" *ngIf="groups">
  <div class="container-fluid mt-5" *ngIf="groups.length > 0">
    <div style="font-size: 2.5em !important;" class="font-weight-bold">Categorías más populares</div>
    <div class="row mt-2">
      <div class="col-md-4 col-lg-3 col-6 border pointer" *ngFor="let group of groups"
           (click)="redirectToGrupo(group.name, group.superGroupId, group.id); windowTop()">
        <div class="d-flex justify-content-center mt-2">
          <div class="h-auto">
            <img *ngIf="group.groupImgUrl" src="./assets/fotos/grupos/{{group.groupImgUrl}}"
                 alt="Imagen {{group.name}}" class="w-100"/>
          </div>
        </div>
        <div class="w-100 text-center">
          {{group.name}}
        </div>
      </div>
    </div>
  </div>
</section>

<section id="app" *ngIf="!appMovil">
  <div [ngStyle]="{'background-image': 'url(assets/web-estatica/inicio/compra-en-camino.webp)'}"
       style="min-height: 75vh !important; background-size: cover;"
       class="w-100 d-flex justify-content-center mt-5 align-items-center">
    <img src="assets/web-estatica/inicio/foto-movil.webp" alt="Foto app móvil" class="mr-3 computer" style="height: 600px !important;">

    <div class="pt-5 pl-2 pr-2">
      <h3 style="font-size: 1.875em !important;" class="text-white">Tu tiempo nos importa</h3>
      <h2 style="font-size: 3.75em !important; line-height: 70px;" class="font-weight-bold text-white mb-0">Compra en</h2>
      <h2 style="font-size: 3.75em !important; line-height: 70px;" class="font-weight-bold text-white">camino</h2>
      <h4 style="font-size: 1.25em !important;" class="font-weight-bold text-white">Tu compra semanal al alcance de tus manos</h4>
      <div class="row" *ngIf="inicioJson">
        <div class="col-md-6 mb-2">
          <a href="{{inicioJson.linkAndroid}}" class="mr-2">
            <img src="assets/web-estatica/inicio/google-play.webp" alt="Descargar en Google Play" style="width: 175px !important;">
          </a>
        </div>
        <div class="col-md-6">
          <a href="{{inicioJson.linkIos}}">
            <img src="assets/web-estatica/inicio/apple-store.webp" alt="Descargar en Google Play" style="width: 175px !important;">
          </a>
        </div>
      </div>
    </div>
  </div>
</section>

<section id="compra-productos" *ngIf="secciones.length > 0">
  <div class="container-fluid mt-5" *ngFor="let seccion of secciones">
    <h2 style="font-size: 2.5em !important;" class="font-weight-bold">{{seccion.texto}}</h2>
    <div [id]="'seccion-' + seccion.grupo" class="carousel slide mt-3" data-ride="carousel">
      <!-- Diapositivas -->
      <div class="carousel-inner">
        <div *ngFor="let item of [].constructor(seccion.paginasCarrousel + 1); let i = index" class="carousel-item"
             [ngClass]="{'active': posicionCarrousel == i}">
          <div class="row mx-auto my-auto w-100">
            <div class="col-lg-3 col-6 border pointer" *ngIf="seccion.articulos.length > (i * 4)"
                 (click)="redirectToArticulo(seccion.articulos[i * 4].groupName, seccion.articulos[i * 4].name, seccion.articulos[i * 4].group, seccion.articulos[i * 4].family, seccion.articulos[i * 4].id )">
              <div class="mb-3 mt-3 text-center font-weight-bold">
                <div>
                  <img *ngIf="seccion.articulos[i * 4].articleImgUrl; else productImg0"
                       class="w-100"
                       src="./assets/fotos/articulos/{{seccion.articulos[i * 4].articleImgUrl}}" alt="{{seccion.articulos[i * 4].name}}"/>
                  <ng-template #productImg0>
                    <img *ngIf="seccion.articulos[i * 4].productImgUrl"
                         class="w-100"
                         src="./assets/fotos/productos/{{seccion.articulos[i * 4].productImgUrl}}" alt="{{seccion.articulos[i * 4].name}}"/>
                  </ng-template>

                  <div>
                    <span class="w-100" *ngIf="seccion.articulos[i * 4].nameClient != null">{{seccion.articulos[i * 4].nameClient}}</span>
                    <span class="w-100" *ngIf="seccion.articulos[i * 4].nameClient == null">{{seccion.articulos[i * 4].name}}</span>
                    <div class="text-danger">{{seccion.articulos[(i * 4)].priceIvaStr}} €</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-6 border pointer" *ngIf="seccion.articulos.length > (i * 4) + 1"
                 (click)="redirectToArticulo(seccion.articulos[(i * 4) + 1].groupName, seccion.articulos[(i * 4) + 1].name, seccion.articulos[(i * 4) + 1].group, seccion.articulos[(i * 4) + 1].family, seccion.articulos[(i * 4) + 1].id )">
              <div class="mb-3 mt-2 text-center font-weight-bold">
                <div>
                  <img *ngIf="seccion.articulos[(i * 4) + 1].articleImgUrl; else productImg0"
                       class="w-100"
                       src="./assets/fotos/articulos/{{seccion.articulos[(i * 4) + 1].articleImgUrl}}" alt="{{seccion.articulos[(i * 4) + 1].name}}"/>
                  <ng-template #productImg0>
                    <img *ngIf="seccion.articulos[(i * 4) + 1].productImgUrl"
                         class="w-100"
                         src="./assets/fotos/productos/{{seccion.articulos[(i * 4) + 1].productImgUrl}}" alt="{{seccion.articulos[(i * 4) + 1].name}}"/>
                  </ng-template>

                  <div>
                    <span class="w-100" *ngIf="seccion.articulos[(i * 4) + 1].nameClient != null">{{seccion.articulos[(i * 4) + 1].nameClient}}</span>
                    <span class="w-100" *ngIf="seccion.articulos[(i * 4) + 1].nameClient == null">{{seccion.articulos[(i * 4) + 1].name}}</span>
                    <div class="text-danger">{{seccion.articulos[(i * 4) + 1].priceIvaStr}} €</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-6 border pointer" *ngIf="seccion.articulos.length > (i * 4) + 2"
                 (click)="redirectToArticulo(seccion.articulos[(i * 4) + 2].groupName, seccion.articulos[(i * 4) + 2].name, seccion.articulos[(i * 4) + 2].group, seccion.articulos[(i * 4) + 2].family, seccion.articulos[(i * 4) + 2].id )">              <div class="mb-3 mt-2 text-center font-weight-bold">
              <div>
                <img *ngIf="seccion.articulos[(i * 4) + 2].articleImgUrl; else productImg0"
                     class="w-100"
                     src="./assets/fotos/articulos/{{seccion.articulos[(i * 4) + 2].articleImgUrl}}" alt="{{seccion.articulos[(i * 4) + 2].name}}"/>
                <ng-template #productImg0>
                  <img *ngIf="seccion.articulos[(i * 4) + 2].productImgUrl"
                       class="w-100"
                       src="./assets/fotos/productos/{{seccion.articulos[(i * 4) + 2].productImgUrl}}" alt="{{seccion.articulos[(i * 4) + 2].name}}"/>
                </ng-template>

                <div>
                  <span class="w-100" *ngIf="seccion.articulos[(i * 4) + 2].nameClient != null">{{seccion.articulos[(i * 4) + 2].nameClient}}</span>
                  <span class="w-100" *ngIf="seccion.articulos[(i * 4) + 2].nameClient == null">{{seccion.articulos[(i * 4) + 2].name}}</span>
                  <div class="text-danger">{{seccion.articulos[(i * 4) + 2].priceIvaStr}} €</div>
                </div>
              </div>
            </div>
            </div>
            <div class="col-lg-3 col-6 border pointer" *ngIf="seccion.articulos.length > (i * 4) + 3"
                 (click)="redirectToArticulo(seccion.articulos[(i * 4) + 3].groupName, seccion.articulos[(i * 4) + 3].name, seccion.articulos[(i * 4) + 3].group, seccion.articulos[(i * 4) + 3].family, seccion.articulos[(i * 4) + 3].id )">              <div class="mb-3 mt-2 text-center font-weight-bold">
              <div>
                <img *ngIf="seccion.articulos[(i * 4) + 3].articleImgUrl; else productImg0"
                     class="w-100"
                     src="./assets/fotos/articulos/{{seccion.articulos[(i * 4) + 3].articleImgUrl}}"  alt="{{seccion.articulos[(i * 4) + 3].name}}"/>
                <ng-template #productImg0>
                  <img *ngIf="seccion.articulos[(i * 4) + 3].productImgUrl"
                       class="w-100"
                       src="./assets/fotos/productos/{{seccion.articulos[(i * 4) + 3].productImgUrl}}"  alt="{{seccion.articulos[(i * 4) + 3].name}}"/>
                </ng-template>


                <div>
                  <span class="w-100" *ngIf="seccion.articulos[(i * 4) + 3].nameClient != null">{{seccion.articulos[(i * 4) + 3].nameClient}}</span>
                  <span class="w-100" *ngIf="seccion.articulos[(i * 4) + 3].nameClient == null">{{seccion.articulos[(i * 4) + 3].name}}</span>
                  <div class="text-danger">{{seccion.articulos[(i * 4) + 3].priceIvaStr}} €</div>
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Controles -->
      <a class="carousel-control-prev" href="#seccion-{{seccion.grupo}}" role="button" data-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Anterior</span>
      </a>
      <a class="carousel-control-next" href="#seccion-{{seccion.grupo}}" role="button" data-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Siguiente</span>
      </a>
    </div>


    <div class="w-100 d-flex justify-content-center mt-3">
      <button class="btn btn-primary text-light font-weight-bold mt-2 p-2 rounded-button"
              (click)="redirectToGrupo(seccion.grupo, 'none', seccion.grupo)">
        Comprar
      </button>
    </div>
  </div>
</section>

<section id="contacto">
  <div [ngStyle]="{'background-image': 'url(assets/web-estatica/inicio/contacto.webp)'}"
       style="min-height: 50vh !important; background-size: cover;"
       class="w-100 d-flex justify-content-center mt-5 align-items-center p-2">
    <div>
      <h3 style="font-size: 1.875em !important;" class="text-white">Estamos encantados de poder atenderle</h3>
      <div style="font-size: 3.75em !important; line-height: 70px;" class="font-weight-bold text-white mb-0">Contacta con</div>
      <div style="font-size: 3.75em !important; line-height: 70px;" class="font-weight-bold text-white">nosotros</div>
      <button class="btn btn-primary text-light font-weight-bold mt-2 p-2 rounded-button" routerLink="/atencion-al-cliente"
              style="width: 200px !important;">
        Contactar
      </button>
    </div>

  </div>
</section>
