import {Component, OnInit} from '@angular/core';
import {CompanyInfo} from '../../../interfaces/company-conf';

@Component({
  selector: 'app-cookies',
  templateUrl: './cookies.component.html',
  styleUrls: ['./cookies.component.css']
})
export class CookiesComponent implements OnInit {

 datosLegalesEmpresa: CompanyInfo;
  constructor() { }

  ngOnInit(): void {  }

}
