<div style="min-height: 100vh">
  <div class="container mb-5" *ngIf="datosLegalesEmpresa != undefined && companyConf != undefined">
    <h3 class="pt-4 mb-3"><a routerLink="/"><i class="fas fa-arrow-left"></i> Volver</a></h3>
    <h1><b>Política de privacidad</b></h1>
    <p>
      En esta declaración de privacidad se explica qué datos personales recopilamos de nuestros usuarios
      y cómo los utilizamos.<br/>
      A través de este sitio web no se recaban datos de carácter personal de los usuarios sin su conocimiento,
      ni se ceden a terceros.<br/>
    </p>
    <p>{{datosLegalesEmpresa.nameR}} es una empresa cuya razón social {{datosLegalesEmpresa.name}} y su CIF: {{datosLegalesEmpresa.cif}}.</p>

    <h2><i><u>Responsable de la Información Personal</u></i></h2>
    <div><b>Identidad del Responsable:</b> {{datosLegalesEmpresa.name}}</div>
    <div><b>Nombre comercial:</b> {{datosLegalesEmpresa.nameR}}</div>
    <div><b>Dirección:</b> {{datosLegalesEmpresa.direction}}</div>
    <div><b>Correo electrónico:</b> {{datosLegalesEmpresa.email}}</div>
    <div><b>Actividad:</b> {{datosLegalesEmpresa.activity}} </div>
    <div><b>Especialidad:</b> {{datosLegalesEmpresa.specialty}} </div><br/>

    <h2><i><u>¿Cómo recopilamos sus datos personales?</u></i></h2>
    <p>Los datos personales que tratamos en {{datosLegalesEmpresa.nameR}} proceden de: formularios o pedidos.</p>

    <h2><i><u>¿Qué Datos Personales utilizamos, y con qué finalidad?</u></i></h2>
    Solicitamos los siguientes datos personales:
    <ul>
      <li>Nombre y apellidos</li>
      <li>Fecha de nacimiento</li>
      <li>Dirección de <span *ngIf="!companyConf.puntosRecogida">envío y</span> facturación: país, provincia, población y dirección</li>
      <li>Datos de contacto: teléfono  móvil y correo electrónico</li>
    </ul>
    <p>  Los datos anteriores pueden ser utilizados para poder realizar un pedido,
      responder a una solicitud, proporcionar información en <a routerLink="/miPerfil">Mi Cuenta > Mi Perfil</a>
      (<span *ngIf="!companyConf.puntosRecogida">ver tus direcciones, </span>datos personales o pedidos realizados).<br/>
      {{datosLegalesEmpresa.nameR}}, no vende, alquila ni cede datos de carácter personal que puedan identificar al usuario,
      ni lo hará en el futuro, a terceros sin el consentimiento previo.
    </p>

    <h2><i><u>¿Con qué finalidades tratamos tu información?</u></i></h2>
    <p>Tratamos tu información personal para operar, prestar y mejorar los Servicios de {{datosLegalesEmpresa.nameR}}
      que ofrecemos a nuestros clientes. Entre estas finalidades se incluyen:</p>
    <ul>
      <li>
        <b>Compra y entrega de productos.</b>
        Utilizamos tu información personal para aceptar y gestionar pedidos,
        <span *ngIf="!companyConf.puntosRecogida">entregar productos, </span>procesar pagos y comunicarnos contigo en relación con pedidos,
        productos y ofertas promocionales.
      </li>

      <li>
        <b>Comunicarnos contigo.</b>
        Utilizamos tu información personal para comunicarnos contigo
        en relación con los Servicios de {{datosLegalesEmpresa.nameR}} a través de diferentes canales
        (teléfono o correo electrónico).
      </li>
    </ul>

    <h2><i><u>¿A qué información tengo acceso?</u></i></h2>
    <p>Puedes acceder a tus datos, incluyendo tu nombre, direcciones
      <span *ngIf="!companyConf.puntosRecogida"> de envío y  </span> de facturación, email, teléfono y
      pedidos realizados anteriormente apartado <a routerLink="/miPerfil">Mi cuenta > Mi Perfil</a> de la página web. </p>

    <h2><i><u>¿Cuánto tiempo conservamos tu información personal?</u></i></h2>
    <p>Conservamos tu información personal a fin de que puedas utilizar los servicios de {{datosLegalesEmpresa.nameR}} de
      forma continuada y durante el tiempo necesario para cumplir los fines descritos en este Aviso de Privacidad,
      según resulte exigible conforme a la legislación (por ejemplo, con fines fiscales y contables),
      o de cualquier otro modo que te hayamos comunicado.</p>

    <h2><i><u>¿Cuáles son sus derechos cuando nos facilita sus datos?</u></i></h2>
    <div>Se considera interesado a la persona cuyos datos personales se procesan,
      motivo por el cual puede beneficiarse de los derechos reconocidos por esta directiva fundamental sobre protección de datos,
      que son:
      <ul>
        <li>El derecho a la información (art. 15)</li>
        <li>El derecho a la limitación del tratamiento (art. 18)</li>
        <li>El derecho de oposición (art. 21)</li>
        <li>El derecho a presentar una reclamación ante una autoridad de control (art. 77)</li>
        <li>El derecho a la portabilidad (art. 20).</li>
      </ul>
    </div>
  </div>
</div>
