export class PriceRoute {
  deliveryKg: number;
  deliveryPrice: number;
  fixedPrice: number;
  minKg: number;

  constructor(deliveryKg, deliveryPrice, fixedPrice, minKg) {
    this.deliveryKg = deliveryKg;
    this.deliveryPrice = deliveryPrice;
    this.fixedPrice = fixedPrice;
    this.minKg = minKg;
  }
}
