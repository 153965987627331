export class ArticleOrder {
  Article: string;
  Group: string;
  SuperGroup: string;
  Quantity: number;
  Lumps: number;
  Format: string;
  Observations: string;
  CutType: string;

  constructor(article: string, quantity: number, lumps: number, format: string, observations: string, cutType: string,
              superGroup: string, group: string) {
  this.Article = article;
  this.Quantity = quantity;
  this.Lumps = lumps;
  this.Observations = observations;
  this.Format = format;
  this.CutType = cutType;
  this.SuperGroup = superGroup;
  this.Group = group;
  }
}

export class BasicArticle {
  Article: string;
  Format: string;
  Quantity: number;
  Lumps: number;
  CutType: string;
  NewLine: boolean;
  Observations: string;
  AddQuantity: boolean = false;

  constructor(article: string, format: string, quantity: number, lumps: number, cutType: string, newLine: boolean) {
    this.Article = article;
    this.Quantity = quantity;
    this.Lumps = lumps;
    this.Format = format;
    this.CutType = cutType;
    this.NewLine = newLine;
  }
}
