export class SummaryOrder {
  totalPriceIva: number;
  totalPrice: number;
  numArticles: string;
  sumKg: number;

  constructor(totalPrice: number, totalPriceIva: number, sumKg: number,numArticles: string) {
    this.totalPrice = totalPrice;
    this.totalPriceIva = totalPriceIva;
    this.sumKg = sumKg;
    this.numArticles = numArticles;
  }

}
